exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-pl-js": () => import("./../../../src/pages/index.pl.js" /* webpackChunkName: "component---src-pages-index-pl-js" */),
  "component---src-pages-localization-en-js": () => import("./../../../src/pages/localization.en.js" /* webpackChunkName: "component---src-pages-localization-en-js" */),
  "component---src-pages-localization-pl-js": () => import("./../../../src/pages/localization.pl.js" /* webpackChunkName: "component---src-pages-localization-pl-js" */),
  "component---src-pages-mentors-en-js": () => import("./../../../src/pages/mentors.en.js" /* webpackChunkName: "component---src-pages-mentors-en-js" */),
  "component---src-pages-mentors-pl-js": () => import("./../../../src/pages/mentors.pl.js" /* webpackChunkName: "component---src-pages-mentors-pl-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-results-en-js": () => import("./../../../src/pages/results.en.js" /* webpackChunkName: "component---src-pages-results-en-js" */),
  "component---src-pages-results-pl-js": () => import("./../../../src/pages/results.pl.js" /* webpackChunkName: "component---src-pages-results-pl-js" */),
  "component---src-pages-summary-2020-en-js": () => import("./../../../src/pages/summary-2020.en.js" /* webpackChunkName: "component---src-pages-summary-2020-en-js" */),
  "component---src-pages-summary-2020-pl-js": () => import("./../../../src/pages/summary-2020.pl.js" /* webpackChunkName: "component---src-pages-summary-2020-pl-js" */),
  "component---src-pages-summary-2022-en-js": () => import("./../../../src/pages/summary-2022.en.js" /* webpackChunkName: "component---src-pages-summary-2022-en-js" */),
  "component---src-pages-summary-2022-pl-js": () => import("./../../../src/pages/summary-2022.pl.js" /* webpackChunkName: "component---src-pages-summary-2022-pl-js" */),
  "component---src-pages-summary-2023-en-js": () => import("./../../../src/pages/summary-2023.en.js" /* webpackChunkName: "component---src-pages-summary-2023-en-js" */),
  "component---src-pages-summary-2023-pl-js": () => import("./../../../src/pages/summary-2023.pl.js" /* webpackChunkName: "component---src-pages-summary-2023-pl-js" */),
  "component---src-pages-summary-2024-en-js": () => import("./../../../src/pages/summary-2024.en.js" /* webpackChunkName: "component---src-pages-summary-2024-en-js" */),
  "component---src-pages-summary-2024-pl-js": () => import("./../../../src/pages/summary-2024.pl.js" /* webpackChunkName: "component---src-pages-summary-2024-pl-js" */),
  "component---src-pages-topics-en-js": () => import("./../../../src/pages/topics.en.js" /* webpackChunkName: "component---src-pages-topics-en-js" */),
  "component---src-pages-topics-pl-js": () => import("./../../../src/pages/topics.pl.js" /* webpackChunkName: "component---src-pages-topics-pl-js" */),
  "component---src-pages-workshops-en-js": () => import("./../../../src/pages/workshops.en.js" /* webpackChunkName: "component---src-pages-workshops-en-js" */),
  "component---src-pages-workshops-pl-js": () => import("./../../../src/pages/workshops.pl.js" /* webpackChunkName: "component---src-pages-workshops-pl-js" */)
}

